import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

export default function FeeCalculator() {
  const navigate = useNavigate();

  const [repairCosts, setRepairCosts] = useState("")
  const [replacementCosts, setReplacementCosts] = useState("")
  const [impairment, setImpairment] = useState("")
  const [pictures, setPictures] = useState("")
  const [pages, setPages] = useState("")
  const [arrivalDistance, setArrivalDistance] = useState("")
  const [arrivalTime, setArrivalTime] = useState("")
  const [communicationFee, setCommunicationFee] = useState("")
  const [rating, setRating] = useState("")
  const [retrievalFee, setRetrievalFee] = useState("")
  const [motorCheckUP, setMotorCheckUP] = useState("")
  const [errorMemory, setErrorMemory] = useState("")
  const [paintLayerMeasurement, setPaintLayerMeasurement] = useState("")
  const [liftingPlatform, setLiftingPlatform] = useState("")

  const [validationError,setValidationError] = useState({})


  const [damageAmount, setDamageAmount] = useState("0,00")
  const [basicFee, setBasicFee] = useState("0,00")
  const [firstPictureSetPrice, setFirstPictureSetPrice] = useState("0,00")
  const [secondPictureSetPrice, setSecondPictureSetPrice] = useState("0,00")
  const [firstWritingCostsSetPrice, setFirstWritingCostsSetPrice] = useState("0,00")
  const [secondWritingCostsSetPrice, setSecondWritingCostsSetPrice] = useState("0,00")
  const [travelDistancePrice, setTravelDistancePrice] = useState("0,00")
  const [travelTimePrice, setTravelTimePrice] = useState("0,00")
  const [communicationFeePrice, setCommunicationFeePrice] = useState("0,00")
  const [ratingPrice, setRatingPrice] = useState("0,00")
  const [retrievalFeePrice, setRetrievalFeePrice] = useState("0,00")
  const [motorCheckUpPrice, setMotorCheckUpPrice] = useState("0,00")
  const [errorMemoryPrice, setErrorMemoryPrice] = useState("0,00")
  const [paintLayerMeasurementPrice, setPaintLayerMeasurementPrice] = useState("0,00")
  const [liftingPlatformPrice, setLiftingPlatformPrice] = useState("0,00")
  const [totalPrice, setTotalPrice] = useState("0,00")

  const calculateFee = async (e) => {
    e.preventDefault();

    const formData = new FormData()

    formData.append('repairCosts', repairCosts)
    formData.append('replacementCosts', replacementCosts)
    formData.append('impairment', impairment)
    formData.append('pictures', pictures)
    formData.append('pages', pages)
    formData.append('arrivalDistance', arrivalDistance)
    formData.append('arrivalTime', arrivalTime)
    formData.append('communicationFee', communicationFee)
    formData.append('rating', rating)
    formData.append('retrievalFee', retrievalFee)
    formData.append('motorCheckUP', motorCheckUP)
    formData.append('errorMemory', errorMemory)
    formData.append('paintLayerMeasurement', paintLayerMeasurement)
    formData.append('liftingPlatform', liftingPlatform)

await axios.post('https://api.gutachtenkosten.de/fcserver/public/api/feecalculator',formData).then(({data})=>{
  setDamageAmount(data.damageAmount)
  setBasicFee(data.basicFee)
  setFirstPictureSetPrice(data.firstPictureSetSum)
  setSecondPictureSetPrice(data.secondPictureSetSum)
  setFirstWritingCostsSetPrice(data.firstWritingCostsSetSum)
  setSecondWritingCostsSetPrice(data.secondWritingCostsSetSum)
  setTravelDistancePrice(data.travelDistanceSum)
  setTravelTimePrice(data.travelTimeSum)
  setCommunicationFeePrice(data.communicationFeePrice)
  setRatingPrice(data.ratingSum)
  setRetrievalFeePrice(data.retrievalFeeSum)
  setMotorCheckUpPrice(data.motorCheckUPSum)
  setErrorMemoryPrice(data.errorMemorySum)
  setPaintLayerMeasurementPrice(data.paintLayerMeasurementSum)
  setLiftingPlatformPrice(data.liftingPlatformSum)

  setTotalPrice(data.totalSum)
}).catch(({response})=>{

  })
}

return (
<Row className="row g-5">
<Col className="col-md-7 col-lg-8">
<h4 className="mb-3">Eingangswerte</h4>
<Form onSubmit={calculateFee}>
  <Row>
    <Col className="col-sm-6">
      <Form.Group controlId="RepairCosts">
        <Form.Label>Reparaturkosten netto</Form.Label>
        <Form.Control type="number" step="0.01" placeholder="0,00" value={repairCosts} onChange={(event)=>{setRepairCosts(event.target.value)}} />
      </Form.Group>
    </Col>
    <Col className="col-sm-6">
      <Form.Group controlId="ReplacementCosts">
        <Form.Label>Wiederbeschaffungswert</Form.Label>
        <Form.Control type="number" step="0.01" placeholder="0,00" value={replacementCosts} onChange={(event)=>{setReplacementCosts(event.target.value)}} />
      </Form.Group>
    </Col>
  </Row>
  <Row>
    <Col className="col-12">
      <Form.Group controlId="Impairment">
        <Form.Label>Wertminderung</Form.Label>
        <Form.Control type="number" step="0.01" placeholder="0,00" value={impairment} onChange={(event)=>{setImpairment(event.target.value)}} />
      </Form.Group>
    </Col>
  </Row>
  <h4 className="mb-3">Nebenkosten</h4>
  <Row>
  <Col className="col-sm-6">
    <Form.Group controlId="nc1">
      <Form.Label>Lichtbilder <span className="text-muted small">(Anzahl Fotos)</span></Form.Label>
      <Form.Control type="number" value={pictures} onChange={(event)=>{setPictures(event.target.value)}} />
    </Form.Group>
  </Col>
  <Col className="col-sm-6">
    <Form.Group controlId="nc2">
      <Form.Label>Schreibkosten <span className="text-muted small">(je Seite)</span></Form.Label>
      <Form.Control type="number" value={pages} onChange={(event)=>{setPages(event.target.value)}} />
    </Form.Group>
  </Col>
  </Row>
  <Row>
  <Col className="col-sm-6">
    <Form.Group controlId="nc3">
      <Form.Label>Anfahrtsstrecke <span className="text-muted small">(in Km)</span></Form.Label>
      <Form.Control type="number" value={arrivalDistance}  onChange={(event)=>{setArrivalDistance(event.target.value)}} />
    </Form.Group>
  </Col>
  <Col className="col-sm-6">
    <Form.Group controlId="nc4">
      <Form.Label>Anfahrtszeit <span className="text-muted small">(in Minuten)</span></Form.Label>
      <Form.Control type="number" value={arrivalTime} onChange={(event)=>{setArrivalTime(event.target.value)}} />
    </Form.Group>
  </Col>
  </Row>
  <Row>
    <Col className="col-sm-6">
      <Form.Group controlId="nc5">
        <Form.Check label="Kommunikationspauschale" onChange={(event)=>{setCommunicationFee(event.target.checked)}} />
      </Form.Group>
    </Col>
    <Col className="col-sm-6">
      <Form.Group controlId="nc6">
        <Form.Check label="Bewertung" onChange={(event)=>{setRating(event.target.checked)}} />
      </Form.Group>
    </Col>
    <Col className="col-sm-6">
      <Form.Group controlId="nc7">
        <Form.Check label="EDV Abrufgebühr" onChange={(event)=>{setRetrievalFee(event.target.checked)}} />
    </Form.Group> 
    </Col>
    <Col className="col-sm-6">
      <Form.Group controlId="nc8">
        <Form.Check label="MotorCheckUP" onChange={(event)=>{setMotorCheckUP(event.target.checked)}} />
      </Form.Group>
    </Col>
    <Col className="col-sm-6">
      <Form.Group controlId="nc9">
        <Form.Check label="Fehlerspeicher prüfen und dokumentieren" onChange={(event)=>{setErrorMemory(event.target.checked)}} />
      </Form.Group>
    </Col>
    <Col className="col-sm-6">
      <Form.Group controlId="nc10">
        <Form.Check label="Lackschichtmessung durchführen und dokumentieren" onChange={(event)=>{setPaintLayerMeasurement(event.target.checked)}} />
      </Form.Group>
    </Col>
    <Col className="col-sm-6">
      <Form.Group controlId="nc11">
        <Form.Check label="Bereitstellung Hebebühne" onChange={(event)=>{setLiftingPlatform(event.target.checked)}} />
      </Form.Group>
    </Col>
  </Row>
  <Button variant="primary" type="submit" >Kosten berechnen</Button>
</Form>
</Col>
<Col className="col-md-5 col-lg-4 order-md-last">
<h4 className="mb-3">Ergebnis</h4>
<ul className="list-group mb-3">
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Grundgebühr</h6> <span className="text-muted small">nach Schadenhöhe ({damageAmount}€)</span>
            </div>
            <span className="text-muted">{basicFee}€
</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Fotokosten 1. Satz</h6>
            </div>
            <span className="text-muted">{firstPictureSetPrice}€
</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Fotokosten 2. Satz</h6>
            </div>
            <span className="text-muted">{secondPictureSetPrice}€</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Schreibkosten</h6>
            </div>
            <span className="text-muted">{firstWritingCostsSetPrice}€</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Schreibkosten (Kopie)</h6>
            </div>
            <span className="text-muted">{secondWritingCostsSetPrice}€</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Fahrtkosten (Strecke)</h6>
            </div>
            <span className="text-muted">{travelDistancePrice}€</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Fahrtkosten (Zeit)</h6>
            </div>
            <span className="text-muted">{travelTimePrice}€</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Kommunikationspauschale</h6>
            </div>
            <span className="text-muted">{communicationFeePrice}€</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Bewertung</h6>
            </div>
            <span className="text-muted">{ratingPrice}€</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">EDV-Abrufgebühr</h6>
            </div>
            <span className="text-muted">{retrievalFeePrice}€</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">MotorCheckUP</h6>
            </div>
            <span className="text-muted">{motorCheckUpPrice}€</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Fehlerspeicher prüfen</h6>
            </div>
            <span className="text-muted">{errorMemoryPrice}€</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Lackschichtmessung</h6>
            </div>
            <span className="text-muted">{paintLayerMeasurementPrice}€</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Hebebühne</h6>
            </div>
            <span className="text-muted">{liftingPlatformPrice}€</span>
          </li>
          <li className="list-group-item d-flex justify-content-between">
            <span>Summe (netto)</span>
            <strong>{totalPrice} €</strong>
          </li>
        </ul>
</Col>
</Row>
)
}
