import * as React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router , Routes, Route, Link } from "react-router-dom";

import FeeCalculator  from "./components/fee/calculator.component";

function App() {
  return (
<div className="container">
<main>
<header className="d-flex flex-wrap justify-content-center py-3 mb-4">
<a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
<span className="fs-4"><img src="https://gutachtenkosten.de/logo.png" alt="Honorarrechner Autoveritas GmbH" width="150" /><small> - Honorarrechner</small></span>
</a>
</header>
<Router>
<Routes>
<Route exact path='/' element={<FeeCalculator />} />
</Routes>
</Router>
</main>
<footer className="footer mt-auto py-3 bg-light">
<div className="container">
<span className="text-muted">&copy; gutachtenkosten.de (1.0.3) </span>
</div>
</footer>
</div>);
}

export default App;
